<template>
  <div :class="isMobile?'div-position-mobile': 'div-position'" style="position:relative;">
    <el-date-picker
        :class="isMobile?'date-picker-mobile':'date-picker'"
        size="small"
        :editable="false"
        v-model="dateValue"
        value-format="yyyy-MM"
        @change="changeDatePicker"
        :picker-options="pickerOptions"
        type="month"
        :placeholder="$t('user_center.select_month')">
    </el-date-picker>
    <div v-if="!isMobile" style="min-height: 500px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color:#000000;opacity: 45%;cursor: pointer"><span @click="goback()">{{ $t('user_center.data') }}</span></el-breadcrumb-item>
        <el-breadcrumb-item style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color:#000000;opacity: 65%">{{ $t('user_center.profit_detail') }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div v-if="profitTotal.length != 0" style="background: white;border-radius: 2px;padding: 0 20px;min-height: 700px">
        <div class="list_title">
          <span>{{ $t('user_center.date') }}</span>
          <span>{{ $t('user_center.transaction_order_of_num') }}</span>
          <span>{{ $t('user_center.rate_total') }}</span>
          <span>{{ $t('user_center.earn') }}(USDT)</span>
          <span style="width: 4%;text-align: right">{{ $t('user_center.details') }}</span>
        </div>

        <div style="display: block;padding: 16px 0;border-bottom: 1px solid #F0F2F5;" v-for="item in profitTotal">
          <span style="display: inline-block;width: 24%;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #151924;">{{ item.date }}</span>
          <span style="display: inline-block;width: 24%;font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ item.trade_count | thousandth }}</span>
          <span style="display: inline-block;width: 24%;font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #52C41A;">{{ item.trade_profit_yield?(item.trade_profit_yield*100)>0?(item.trade_profit_yield*100):'0.00':'0.00' | roundTwoFilter }}%</span>
          <span style="display: inline-block;width: 24%;font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{item.trade_profit>=0?'+':'' }}{{ item.trade_profit?item.trade_profit:'0.00'| roundTwoFilter | thousandth }}<span style="font-size: 14px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈￥{{ item.trade_profit?(item.trade_profit * rate):'0.00' | roundTwoFilter | thousandth }}</span></span>
          <span style="display: inline-block;width: 4%;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #AB54DB;text-align: right;cursor: pointer" @click="gotoDetails(item)">{{ $t('user_center.see') }}</span>
        </div>
      </div>

      <el-empty v-else style="margin-top: 200px"></el-empty>
    </div>
    <div v-if="isMobile" style="background: white;min-height: 600px;">
      <div style="height: 40px;border-bottom: 1px solid #F0F2F5;padding: 20px 20px 0">
        <img style="display: inline-block;height: 24px;vertical-align: middle" src="../../assets/img/robot/phone_back.png" @click="goback()">
        <span style="font-size: 17px;font-weight: 500;vertical-align:middle;color: #000613;margin-left: 10px">{{ $t('user_center.profit_detail') }}</span>
      </div>
      <div v-for="item in profitTotal" style="padding: 16px 0;border-bottom: 1px solid #F0F2F5;margin: 0 20px" @click="gotoDetails(item)">
        <div style="display: inline-block; width: 30%">
          <span class="title" style="display: block">{{ $t('user_center.date') }}</span>
          <span class="content" style="display: block">{{ item.date }}</span>
        </div>
        <div style="display: inline-block; width: 20%">
          <span class="title" style="display: block">{{ $t('user_center.transaction_order') }}</span>
          <span class="content" style="display: block">{{ item.trade_count | thousandth }}</span>
        </div>
        <div style="display: inline-block; width: 20%">
          <span class="title" style="display: block">{{ $t('user_center.rate_total') }}</span>
          <span class="content" style="display: block">{{ item.trade_profit_yield?(item.trade_profit_yield*100)>0?(item.trade_profit_yield*100):'0.00':'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block; width: 30%">
          <span class="title" style="display: block">≈￥{{ item.trade_profit?(item.trade_profit * rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span class="content" style="display: block;color: #E3B936">{{item.trade_profit>=0?'+':'' }}{{ item.trade_profit?item.trade_profit:'0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
    </div>

    <el-dialog
        class="dialog-details"
        :title="$t('user_center.daily_revenue_details')"
        :visible.sync="showProfitDetails"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="500px"
        style="height: auto;overflow: unset;-webkit-overflow-scrolling: touch">

      <div style="width: 100%; border-bottom: 1px solid #EAECEF"></div>

      <div style="padding: 20px 0;border-bottom: 1px solid #F0F2F5;margin: 0 20px">
        <span style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #333333;">{{ detailsItem.date }}{{ $t('user_center.robot_profit_total') }}(USDT)</span>
        <span style="display:block;margin-top: 10px;font-size: 20px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ detailsItem.trade_profit?detailsItem.trade_profit:'0.00' | roundTwoFilter | thousandth }}<span style="font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;"> ≈¥{{ detailsItem.trade_profit?(detailsItem.trade_profit * rate):'0.00' | roundTwoFilter | thousandth }}</span></span>
      </div>

      <!-- 黄金三角 -->
      <div v-if="profitTotalDetails.triangle_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.sj') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.triangle_count?profitTotalDetails.triangle_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.triangle_profit_yield?(profitTotalDetails.triangle_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.triangle_profit?(profitTotalDetails.triangle_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.triangle_profit>=0?'+':'' }}{{ profitTotalDetails.triangle_profit?profitTotalDetails.triangle_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- BB -->
      <div v-if="profitTotalDetails.bb_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.bb_trade') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.bb_count?profitTotalDetails.bb_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.bb_profit_yield?(profitTotalDetails.bb_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.bb_profit?(profitTotalDetails.bb_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.bb_profit>=0?'+':'' }}{{ profitTotalDetails.bb_profit?profitTotalDetails.bb_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- UB -->
      <div v-if="profitTotalDetails.ub_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.ub_trade') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.ub_count?profitTotalDetails.ub_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.ub_profit_yield?(profitTotalDetails.ub_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.ub_profit?(profitTotalDetails.ub_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.ub_profit>=0?'+':'' }}{{ profitTotalDetails.ub_profit?profitTotalDetails.ub_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 网格 -->
      <div v-if="profitTotalDetails.grid_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.semi') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.grid_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.grid_count?profitTotalDetails.grid_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.grid_profit_yield?(profitTotalDetails.grid_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.grid_profit?(profitTotalDetails.grid_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.grid_profit>=0?'+':'' }}{{ profitTotalDetails.grid_profit?profitTotalDetails.grid_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 马丁 -->
      <div v-if="profitTotalDetails.mg_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.semi') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.martin_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.mg_count?profitTotalDetails.mg_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.mg_profit_yield?(profitTotalDetails.mg_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.mg_profit?(profitTotalDetails.mg_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.mg_profit>=0?'+':'' }}{{ profitTotalDetails.mg_profit?profitTotalDetails.mg_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
      <!-- 合约 -->
      <div v-if="profitTotalDetails.supreme_count" style="padding: 10px 0;border-bottom: 1px solid #F0F2F5;padding: 16px 0;margin: 0 20px">
        <div style="display: inline-block;width: 25%;">
          <span class="details_title" style="">{{ $t('robot.auto') }}</span>
          <span style="display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;">{{ $t('robot.grid_contract_strategy') }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.transaction_order') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.supreme_count?profitTotalDetails.supreme_count: '0' | thousandth }}</span>
        </div>
        <div style="display: inline-block;width: 20%;">
          <span class="details_title" style="">{{ $t('user_center.rate_total') }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #333333;">{{ profitTotalDetails.supreme_profit_yield?(profitTotalDetails.supreme_profit_yield*100):'0.00' | roundTwoFilter }}%</span>
        </div>
        <div style="display: inline-block;width: 35%;">
          <span style="display:block;font-size: 12px;font-family: DINPro-Regular, DINPro;font-weight: 400;color: #666666;">≈¥{{ profitTotalDetails.supreme_profit?(profitTotalDetails.supreme_profit*rate):'0.00' | roundTwoFilter | thousandth }}</span>
          <span style="display:block;font-size: 14px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #E3B936;">{{ profitTotalDetails.supreme_profit>=0?'+':'' }}{{ profitTotalDetails.supreme_profit?profitTotalDetails.supreme_profit: '0.00' | roundTwoFilter | thousandth }} USDT</span>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getDayProfitByUserAndDate, getUSDTRateByCode, userProfitTableByMonth} from "../../server/user";

export default {
  name: "profit_details",
  data(){
    return{
      isMobile: false,
      profitTotal: [],
      profitTotalDetails: {},
      showProfitDetails: false,
      rate: 0,
      dateValue: '',
      params:{
        year:'',
        month: '',
      },
      detailsItem: {},
      pickerOptions:{
        disabledDate(time){
          return (time.valueOf()<(new Date('2021-11').valueOf()))||time.valueOf()>Date.now() || time.valueOf() < new Date(JSON.parse(localStorage.getItem('userInfo')).create_time).valueOf()
        }
      },
    }
  },

  mounted() {
    if (this.$util._isMobile()){
      this.isMobile = true
    }
    this.getUSDTRateByCode()
    this.params.year = new Date().getFullYear()
    this.params.month = (new Date().getMonth()+1)
    this.dateValue = this.params.year + '-' + this.params.month
    this.userProfitTableByMonth(this.params.year, this.params.month)
  },

  methods:{
    goback(){
      this.$router.go(-1)
    },

    gotoDetails(item){
      if (this.isMobile){
        localStorage.setItem('detailsItem', JSON.stringify(item))
        this.$router.push('./profit_details_every')
      } else {
        this.detailsItem = item
        this.getDayProfitByUserAndDate(item.date)
      }
    },

    async getUSDTRateByCode(){ // 获取实时换算比率
      const res = await getUSDTRateByCode()
      if (!res) return
      if (res.success) {
        this.rate = res.data
      }
    },

    changeDatePicker(){
      if (!this.dateValue) return
      this.params.year = this.dateValue.split('-')[0]
      this.params.month = this.dateValue.split('-')[1].replace(/\b(0+)/gi,"")
      this.userProfitTableByMonth()
    },

    async userProfitTableByMonth(){ // 获取收益统计
      const res = await userProfitTableByMonth(this.params)
      if (!res) return
      if (res.success){
        this.profitTotal = res.data
        for (let i = 0; i < this.profitTotal.length; i++) {
          this.profitTotal[i].date = this.profitTotal[i].profit_day.split(' ')[0]
          this.profitTotal[i].sortDate = new Date(this.profitTotal[i].date.replaceAll('-', '/')).getTime()
        }
        this.profitTotal = this.profitTotal.sort(this.compare('sortDate'))
      }
    },

    async getDayProfitByUserAndDate(date){ // 统计明细
      this.params.profitDay = date
      const res = await getDayProfitByUserAndDate(this.params)
      if (!res) return
      if (res.success){
        if (!res.data || res.data == null || res.data.length == 0) return
        this.profitTotalDetails = res.data[0]
        this.showProfitDetails = true
      }
    },

    compare(p){ //这是比较函数
      return function (m, n) {
        var a = m[p];
        var b = n[p];
        return b - a; //降序
      }
    },
  },
}
</script>

<style scoped>
.list_title{
  display: block;
  margin-top: 12px;
  border-bottom: 1px solid #F0F2F5;
}
.list_title span{
  display: inline-block;
  padding: 20px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #87888A;
  width: 24%;
}

.title{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.content{
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #333333;
}
.dialog-details >>>.el-dialog__body{
  padding: 10px 0px 30px;
}

.dialog_details_title {
  display: block;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  font-size: 12px;
}

.date-picker{
  position: absolute;
  top: -10px;
  right: 0px;
  cursor: pointer;
}

.date-picker >>>.el-input__inner{
  cursor: pointer;
}

.date-picker-mobile{
  position: absolute;
  top: 14px;
  right: 20px;
  width: 140px;
  cursor: pointer;
}

.div-position{
  width: 1200px;
  margin: 0 auto 20px;
}

.div-position-mobile{
  width: 100%;
  margin-bottom: 20px;
}


</style>
